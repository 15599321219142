import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../organisatieeffectiviteit.scss"
import kantoor from "../../images/combined-training.png"
import outsidebox from "../../images/case-edit.png"

const Methodiekencoachopleiding = () => {
  return (
    <Layout>
      <SEO
        title="Uitgebreide Zipperr case opleiding"
        description="In deze training zul je meer te weten komen over het systemisch werken in combinatie met de drijfveren."
      />
      <div
        style={{
          width: "100vw",
          height: "250px",
          overflow: "hidden",
          backgroundImage: `url(${kantoor})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/*<img style={{width: '100vw'}} src={office} alt=""/>*/}
      </div>
      <div className="highlightedText containerPadModified">
        <div className="heading">
          <div>
            <h1 className={"subTitleContent centerText"}>
              Zipper Case® opleiding
            </h1>
          </div>
        </div>
        <div className="subhead">
          <p className=" afterlife">Zipper Case® opleiding</p>
        </div>
        <p>
          het doel is mensen inzicht en bewustwording geven in werksituaties,
          gezinssituaties, gebeurtenissen of conflicten
        </p>
      </div>

      {/* <p
        style={{
          textAlign: "center",
          display: "block",
          width: "100%",
          background: "#c4ced4",
          color: "red",
          padding: "5px 0",
        }}
      >
        <strong>
          Een Profile Dynamics-certificering is vereist als basis.
        </strong>
      </p> */}
      <div className={"containerPad flexy"}>
        <div
          className={"half block imger"}
          style={{
            overflow: "hidden",
            backgroundImage: `url(${outsidebox})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>

        <div className={"half block contntr"}>
          <h2>Zipper Case® opleiding</h2>
          <p>
            In deze training zul je meer te weten komen over het systemisch
            werken in combinatie met de drijfveren. Hoe kunnen we systemisch de
            drijfveren inzetten om mensen en organisaties verder te helpen in
            hun ontwikkeling. Jouw kennis omtrent systemisch werken zal u veel
            duidelijk maken over wat er gebeurt tussen mensen en waarom. De
            training bevat uitgebreide uitleg over het o.a. systemisch werken en
            leiderschap in combinatie met de kleuren- en waardensystematiek in
            organisaties. Wij wensen je veel plezier met het volgen van deze
            cursus.
          </p>
          <h3>Onderwerpen</h3>
          <p>In deze training ga je aan de slag met zaken als:</p>
          <ul>
            <li>• Wat drijft mensen? </li>
            <li>• Hoe werkt een systeem?</li>
            <li>• Het onbewuste bewust maken? </li>
            <li>• Hoe hangt het systeem samen met gedrag? </li>
            <li>• Hoe werkt men met associatiekaarten? </li>
            <li>
              • Toepassingen - personal coaching, teambuilding en
              organisatieverandering
            </li>
            <li>• Wat zijn uw doelen met de Profile Dynamics Zipper Case®?</li>
          </ul>
          <h3>Het doel van de training</h3>
          <p>
            Met deze certificeringsopleiding kunt de Profile Dynamics Zipper
            Case® professioneel inzetten in je coaching, training en
            managementactiviteiten. Deze opleiding is een intensieve scholing in
            opstellingen en leiderschap voor mens en organisatie.
          </p>
          <p>
            het doel is mensen inzicht en bewustwording geven in werksituaties,
            gezinssituaties, gebeurtenissen of conflicten. Dit inzicht kan als
            basis dienen om het patroon/gedachten te doorbreken. De
            Zipper®pionnen zijn een hulpmiddel om organisatieopstellingen en
            familieopstellingen uit te voeren zonder groep.
          </p>

          <p className={"btn-container"}>
            <Link
              to={"/contact"}
              className="btn primary-lg round expand-on-hover"
            >
              Aanmelden of meer informatie
            </Link>
          </p>
        </div>
      </div>

      <div className="highlightedText containerPadModified">
        {/*<div className="tiny-header centerText">Breinkracht organisatieadvies op basis van de Zipperr® Methode</div>*/}
        <div className="heading">
          <div>
            <h1 className={"subTitleContent centerText"}>
              Uitgebreide professionele coachopleiding
            </h1>
          </div>
        </div>
        <div className="subhead">
          <p className=" afterlife">Methodieken</p>
        </div>
        <p>De volgende methodieken komen aan bod:</p>
        <ul
          style={{
            listStyleType: "circle",
          }}
        >
          <li>Wat drijft mensen? </li>
          <li>Hoe werkt een systeem?</li>
          <li>Het onbewuste bewust maken? </li>
          <li>Hoe hangt het systeem samen met gedrag? </li>
          <li>Hoe werkt men met associatiekaarten? </li>
          <li>
            Toepassingen - personal coaching, teambuilding en
            organisatieverandering
          </li>
          <li>Wat zijn uw doelen met de Profile Dynamics Zipper Case®?</li>
        </ul>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    placeholderImageMobile: file(relativePath: { eq: "briefcase.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    placeholderImageLaptop: file(relativePath: { eq: "case-edit.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 600, height: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    placeholderImageDesktop: file(relativePath: { eq: "case-edit.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 1200, height: 1200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ziperCaseImg: file(relativePath: { eq: "ad-img.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default Methodiekencoachopleiding
